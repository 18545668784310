import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import SideBarDonate from '../components/SideBarDonate/SideBarDonate';
import PageLayout from '../components/PageLayout/PageLayout';
import SectionLayout from '../components/SectionLayout/SectionLayout';
import SEO from '../components/seo';

const DonatePostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;
  const {
    donateSettings: { current, goal },
  } = data;

  return (
    <PageLayout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <SectionLayout
        title="News"
        sidebar={<SideBarDonate current={current} goal={goal} />}
      >
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline" style={{ fontWeight: 'normal' }}>
              {post.frontmatter.title}
            </h1>
            <p>{post.frontmatter.date}</p>
          </header>
          {post.frontmatter.banner ? (
            <GatsbyImage
              alt={post.frontmatter.title}
              image={getImage(post.frontmatter.banner)}
            />
          ) : post.frontmatter.thumbnail ? (
            <GatsbyImage
              alt={post.frontmatter.title}
              image={getImage(post.frontmatter.thumbnail)}
            />
          ) : null}
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <hr />
          <footer></footer>
        </article>
        <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </SectionLayout>
    </PageLayout>
  );
};

export default DonatePostTemplate;

export const pageQuery = graphql`
  query DonatePostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    donateSettings: settingsYaml(section: { eq: "donate" }) {
      current
      goal
      reason
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        banner {
          childImageSharp {
            gatsbyImageData(width: 1000, formats: [AUTO, WEBP, AVIF])
          }
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 1000, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
